import React, { useEffect, useState } from 'react';
import '../styles/Appeal.css';

interface Appeal {
  [key: string]: any;
}

const Appeals: React.FC = () => {
  const [appeals, setAppeals] = useState<Appeal[]>([]);

  useEffect(() => {
    const fetchAppeals = () => {
      const requestData = {
        action: 'GET_APPEALS',
        client_id: 'test'
      };
      const jsonString = JSON.stringify(requestData);

      if (window.ws && window.ws.readyState === WebSocket.OPEN) {
        window.ws.send(jsonString);
      } else {
        console.error('WebSocket не доступен');
      }
    };

    fetchAppeals();

    const handleMessage = (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);
        if (data.appeals) {
          setAppeals(data.appeals);
        }
      } catch (error) {
        console.error('Ошибка при разборе JSON:', error);
      }
    };

    window.ws.addEventListener('message', handleMessage);

    return () => {
      window.ws.removeEventListener('message', handleMessage);
    };
  }, []);

  if (appeals.length === 0) {
    return <div>Загрузка обращений...</div>;
  }

  const columns = Object.keys(appeals[0]);

  return (
    <div className="appeals-container">
      <h2>Список обращений</h2>
      <div className="table-responsive">
        <table className="appeals-table">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column}>{formatColumnName(column)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {appeals.map((appeal, index) => (
              <tr key={index}>
                {columns.map((column) => (
                  <td key={`${index}-${column}`}>
                    {column === 'attached_files' && appeal[column] ? (
                      <a href={appeal[column]} target="_blank" rel="noopener noreferrer">
                        📎 Скачать
                      </a>
                    ) : (
                      appeal[column]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function formatColumnName(name: string): string {
  return name
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export default Appeals;

